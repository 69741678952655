@import './_toolkit-inverse.scss';
@import './variables.scss';

$font-family: 'Open Sans',
Helvetica,
Arial,
sans-serif;

#root {
  padding-bottom: 75px;
}

.main-content {
  padding-top: 15px;
}

.content-title {
  padding-top: 15px;
}

.cursor-pointer {
  cursor: pointer;
}

.nav-fill {
  .nav-item {
    min-width: 100px;
  }
}

.nav-item {
  font-weight: 500;
}

.btn-group {
  z-index: 0;

  button.active {
    background: theme-color('primary');
  }
}

.dropdown-item {
  cursor: pointer;
}

.image-mosaic,
.text-mosaic {
  background-color: #ffffff;
  color: #0d0f11;
  border: 1px solid #6c757d;

  .mosaic-heading {
    text-align: center;
    font-size: large;
    font-weight: 700;
  }

  .mosaic-label {
    text-align: center;
    font-size: small;
    font-weight: 500;
    width: 100%;
  }

  .mosaic-description {
    text-align: center;
    font-size: small;
  }

  &.selected {
    background-color: #d3f8d6;
    border: 1px solid #19AB27;
    box-shadow: inset 0 0 1px 2px #19AB27;
    position: relative;
  }

  &.disabled {
    background-color: #aaaaaa;
  }

  &:hover:not(.selected) {
    background-color: #aaaaaa;
  }

  .img-container {
    padding-bottom: 75%;
    height: 0;
    position: relative;

    img {
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;
      max-width: 100%;
      max-height: 100%;
      display: block;
      position: absolute;
    }

    @media screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {

      /* IE10+ specific styles go here */
      img {
        margin-left: -50% !important;
      }
    }
  }
}

.hr-divider {
  margin-bottom: 18px;

  h3 {
    font-size: large;
    z-index: 0;
  }
}

.page-title {
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: left;
  text-transform: uppercase;
  font-size: 1.5em;

  .subtitle {
    font-size: 0.6em;
  }
}

.form-title {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.5em;
}

.dropzone .dz-preview .dz-error-message {
  display: none !important;
}

.text-break-line {
  white-space: pre-line;
}

.form-group {
  .form-label {
    font-weight: bold;
    font-size: 15px;
  }
}

.document-item {
  padding: 0.5rem 0.25rem 0.5rem 0.25rem;
}

.clearfix {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}

.bg-none {
  background: transparent;
  border: none;
}

.show-validations {
  div.input-group.invalid {

    input,
    textarea[mandatory*="true"] {
      border: 1px solid red;
    }

    input,
    textarea[mandatory*="true"]:focus {
      border: 1px solid red;
    }
  }

  .react-select-container.highlighted {
    border: 1px solid red;
    border-radius: 4px;
  }
}


.notification-manager {
  .message {
    white-space: pre-line;
  }
}

.text-lg {
  font-size: large;
}