.custom-option {
  .img {
    height: auto !important;
    width: auto !important;
    max-height: 3em;
    max-width: 3em;
    margin-right: 10px;
    margin-top: 5px;
  }
}
