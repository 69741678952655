.show-validations {
    .validation-feedback {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #e64759;
    }
}

.hide-validations {
    .validation-feedback {
        display: none;
    }
}