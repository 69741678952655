@import "theme/_variables-inverse.scss";

$icon-font-path: "~theme/fonts/";
$body-bg: $gray-900;
@import "theme/theme.scss";

.fa.wrapped {
    display: inline-block;
    border-radius: 5px;
    box-shadow: 0px 0px 2px #888;
    padding: 0.5em 0.8em;
}

.react-contexify__item:not(.react-contexify__item--disabled):hover > .react-contexify__item__content,
.react-contexify__item:not(.react-contexify__item--disabled):focus > .react-contexify__item__content {
    color: black !important;
    background-color: #c4a700 !important;
}
