.table {
    color: $body-color !important;

    thead th {
        border-bottom: none;
    }
}

.table-hover tbody tr:hover {
    color: $body-color !important;
}

.table-full {
    @include media-breakpoint-up(sm) {
        margin-right: (($grid-gutter-width / -2) - 1);
        margin-left: (($grid-gutter-width / -2) - 1);
    }
}

// Toolbar type thing above tables
.table-actions {
    padding-bottom: 15px;
}

.table-mobile {
    @media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
        /* Force table to not be like tables anymore */
        table, thead, tbody, th, td, tr {
            display: block;
        }
        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }
        tr {
            border: 1px solid #ccc;
            margin-bottom: 15px;
        }
        tr:last-child {
            margin-bottom: 0 !important;
        }
        td {
            /* Behave  like a "row" */
            border: none;
            border-bottom: 1px dashed #ccc;
            position: relative;
            text-align: right !important;
        }
        td:first-child {
            text-align: center !important;
            display: block;
            font-weight: bold;
            border-bottom: 1px solid #ccc;
            background: white;
            color: black;
        }
        td:last-child {
            border-bottom: none;
        }
        td:before {
            font-weight: bold;
            margin-right: 10px;
            content: attr(data-title);
            display: block;
            width: 45%;
            position: absolute;
            text-align: left;
        }
        td:first-child>:first-child {
            margin-left: 5px;
        }
        td {
            ul {
                text-align: right !important;

                li {
                    list-style: none;
                }
            }
        }
    }
    
    /* Smartphones (portrait and landscape) */
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        body {
            padding: 0;
            margin: 0;
            width: 320px;
        }
    }
    /* iPads (portrait and landscape) */
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        body {
            width: 495px;
        }
    }
}