.dropdown-menu button {
    padding: 0 !important;
}

.dropdown-menu a {
    display: block;
    padding: 0.25rem 1.5rem;
}

.loggedin-user {
    font-weight: 500;
    line-height: 40px;
}