.custom-google-button {
    color: white;
    font-weight: bold;
    padding: 0px 0px 0px 15px;
    display: block;
    height: 2.5em;
    line-height: 2.25em;
    position: relative;
    width: 100%;
    text-align: left;
    cursor: pointer;
    box-sizing: border-box;
    background-color: rgb(66, 133, 244);
    border-radius: 0.2em;
    border-width: 0.125em;
    border-style: solid;
    overflow: inherit;
    transition: all 0.3s ease 0s;
    text-decoration: none;
    border-color: rgb(66, 133, 244);

    i {
        float: right;
        color: rgb(66, 133, 244);
        background-color: white;
        height: 100%;
        width: 30px;
        padding: 5px;
        font-size: 1.5rem;
    }
}

.custom-google-button:hover {
    background-color: rgb(51, 103, 214);
    border-color: rgb(51, 103, 214);
}

.custom-facebook-button {
    color: white;
    font-weight: bold;
    padding: 0px 0px 0px 15px;
    display: block;
    height: 2.5em;
    line-height: 2.25em;
    position: relative;
    width: 100%;
    text-align: left;
    cursor: pointer;
    box-sizing: border-box;
    background-color: rgb(60, 90, 153);
    border-radius: 0.2em;
    border-width: 0.125em;
    border-style: solid;
    overflow: inherit;
    transition: all 0.3s ease 0s;
    text-decoration: none;
    border-color: rgb(60, 90, 153);

    i {
        float: right;
        color: rgb(60, 90, 153);
        background-color: white;
        height: 100%;
        width: 30px;
        padding: 5px 5px 0px 8px;
        font-size: 1.5rem;
    }
}


.custom-facebook-button:hover {
    background-color: lighten(rgb(60, 90, 153), 5%);
    border-color: lighten(rgb(60, 90, 153), 5%);
}