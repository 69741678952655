.inline-input.input-group-text {
    padding: 0.2rem !important;
    height: 1.5rem !important;
}

.inline-input.form-control {
    padding: 0.2rem !important;
    height: 1.5rem !important;
}

input.inline-input::-webkit-inner-spin-button,
input.inline-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input.inline-input {
    -moz-appearance: textfield;
}